import BeginEndProcessor from "../base/BeginEndProcessor";

export default class DiagMethods extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Проверка гипотез'; // вместо "Данные психологического обследования"

        this.beginEvents = [
            'chapter&quot;:3,',
            'chapteru0022:3'
        ];
        this.endEvents = [
            'MainDiagnos, click back',
            'MainDiagnos, click main menu',
            'MainDiagnos, click apply',
            'View Instruction'
        ];
    }

    tryToApply(event)
    {
        super.tryToApply(event);

        //Написание гипотез - 2 мин
        if (this.contains(event, 'diag: (enter to input field)'))
        {
            this.beginFormulationTime = new Date(event.timestamp);
        } else
        {
            if (this.contains(event, 'diag: ( )'))
            {
                this.totalFormulationTime += new Date(event.timestamp) - this.beginFormulationTime;
                this.beginFormulationTime = null;

                //Сформулировал: ..............
                const enteredMatch = event.message.match(/diag\s:\s*\((.*)\)/s)
                if (enteredMatch && enteredMatch[1])
                {
                    if (enteredMatch[1] !== 'enter to input field')
                    {
                        this.entered = enteredMatch[1].replaceAll('u0022', '"').replaceAll('u0027', "'");
                    }
                }
            }
        }

        if (this.contains(event, 'Diag read (false'))
        {
//            this.diagsReadCount++;
            const numberProblem = event.message.match(/\((.*,(u0022|&quot;)(.*)(u0022|&quot;))\)/)
            if (numberProblem && numberProblem[3])
            {
                this.methods.push(numberProblem[3]);
            }
        }
    }
}
