import BeginEndProcessor from "../base/BeginEndProcessor";

export default class DiagMethods extends BeginEndProcessor
{
    constructor(events)
    {
        super(events);
        this.eventName = 'Проверка гипотез'; // вместо "Данные психологического обследования"

        this.beginEvents = [
            'chapter&quot;:3,',
            'chapteru0022:3'
        ];
        this.endEvents = [
            'MainDiagnos, click back',
            'MainDiagnos, click main menu',
            'MainDiagnos, click apply',
            'View Instruction'
        ];
    }
}
